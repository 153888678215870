import React, { createContext, useEffect, useState } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { ApiClient } from "../services/api";
import { Api, User, UserData } from "./types";

const defaultApi: Api = {
  client: new ApiClient(""),
  setClient: () => {},
  resetClient: () => {},
};

const defaultUser: User = {
  data: {
    account_id: "",
    email: "",
    username: "",
    first_name: "",
    last_name: "",
    is_influencer: false,
  },
  setUser: () => {},
  signOut: () => {},
};

export const ApiContext: React.Context<Api> = createContext(defaultApi);
export const UserContext: React.Context<User> = createContext(defaultUser);

export default function Root(props) {
  const [user, setUser] = useState(defaultUser);
  const [api, setApi] = useState(defaultApi);

  useEffect(() => {
    if (typeof window !== "undefined") {
      console.log("use effecting in Root");
      const cachedToken = window.localStorage.getItem("token");
      applyCachedToken(cachedToken);
      const cachedUser = window.localStorage.getItem("user");
      applyCachedUser(cachedUser);
    }
  }, []);

  function applyCachedToken(token: string | undefined) {
    if (!!token) {
      setApi({
        client: new ApiClient(token),
        setClient: setApi,
        resetClient: () => {
          setApi(defaultApi);
        },
      });
    } else {
      setApi({
        client: defaultApi.client,
        setClient: setApi,
        resetClient: () => {
          setApi(defaultApi);
        },
      });
    }
  }

  function applyCachedUser(userJson: string | undefined) {
    if (!!userJson) {
      const user: UserData = JSON.parse(userJson);
      setUser({
        data: user,
        setUser,
        signOut: () => {
          setUser(defaultUser);
        },
      });
    } else {
      setUser({
        data: defaultUser.data,
        setUser: setUser,
        signOut: () => {
          setUser(defaultUser);
        },
      });
    }
  }

  return (
    <ChakraProvider>
      <ApiContext.Provider value={api}>
        <UserContext.Provider value={user}>
          {props.children}
        </UserContext.Provider>
      </ApiContext.Provider>
    </ChakraProvider>
  );
}
