import {
  AccountCreateRequest,
  AccountCreateResponse,
  AccountLoginRequest,
  AccountLoginResponse,
  InfluencerUpdateRequest,
  InfluencerUpdateResponse,
  AccountUserInformationRequest,
  AccountUserInformationResponse,
  AccountModel,
  CollectionCreateRequest,
  CollectionCreateResponse,
  RedeemInviteRequest,
  RedeemInviteResponse,
  CollectionReadRequest,
  CollectionReadResponse,
  CollectionDeleteRequest,
  CollectionDeleteResponse,
  CollectionsUpdateRequest,
  CollectionUpdateResponse,
  GetTikTokLoginResponse,
  SuccessResponse,
  CreateTikTokConnectionRequest,
  RemoveCollectionPostsRequest,
  GetCollectionPostsRequest,
  GetCollectionsPostsResponse,
  RemoveCollectionsPostsResponse,
  GetInfluencerPostsRequest,
  GetInfluencerPostsResponse,
} from "./api_types";

export class ApiClient {
  token: string;
  host: string;

  constructor(token: string) {
    this.token = token;
    this.host = process.env.GATSBY_API_URL;
    console.log("api", this.host);
  }

  async get<T>(path: string, headers: HeadersInit = {}): Promise<T> {
    if (typeof window !== "undefined") {
      const token = window.localStorage.getItem("token");
      if (!!token && !this.token) {
        this.token = token;
      }
    }
    if (!!this.token) {
      headers["Authorization"] = this.token;
    }
    headers["Content-Type"] = "application/json";
    return await (
      await fetch(`${this.host}${path}`, {
        headers,
      })
    ).json();
  }

  async post<T>(
    path: string,
    data: object,
    headers: HeadersInit = {}
  ): Promise<T> {
    if (typeof window !== "undefined") {
      const token = window.localStorage.getItem("token");
      if (!!token && !this.token) {
        this.token = token;
      }
    }
    if (!!this.token) {
      headers["Authorization"] = this.token;
    }
    headers["Content-Type"] = "application/json";
    return await (
      await fetch(`${this.host}${path}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      })
    ).json();
  }

  async put<T>(
    path: string,
    data: object,
    headers: HeadersInit = {}
  ): Promise<T> {
    if (!!this.token) {
      headers["Authorization"] = this.token;
    }
    headers["Content-Type"] = "application/json";
    return await (
      await fetch(`${this.host}${path}`, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(data),
      })
    ).json();
  }

  async ping() {
    console.log("pinging", `${this.host}/ping`);
    return this.get("/ping");
  }

  // accounts

  async createAccount(
    accountCreateRequest: AccountCreateRequest
  ): Promise<AccountCreateResponse> {
    console.log(accountCreateRequest);
    try {
      return this.post("/account", accountCreateRequest);
    } catch (err) {
      console.log(err);
    }
  }

  async accountLogin(
    accountCreateRequest: AccountLoginRequest
  ): Promise<AccountLoginResponse> {
    return this.post("/account/login", accountCreateRequest);
  }

  async getAccountUserInfo(
    accountCreateRequest: AccountUserInformationRequest
  ): Promise<AccountUserInformationResponse> {
    return this.get(
      `/account/user_information/${accountCreateRequest.account_id}`
    );
  }

  async getAccount(): Promise<AccountModel> {
    return this.get("/account");
  }

  // influencers

  async getInfluencerByAccountId(accountId: string): Promise<any> {
    return this.get(`/influencers/${accountId}`);
  }

  async getInfluencers() {
    return this.get(`/influencers`);
  }

  async updateInfluencer(
    influencerUpdateRequest: InfluencerUpdateRequest
  ): Promise<InfluencerUpdateResponse> {
    return this.put(`/influencers`, influencerUpdateRequest);
  }

  // collections

  async getCollections() {
    return this.get(`/collections`);
  }

  async createCollection(
    collectionCreateRequest: CollectionCreateRequest
  ): Promise<CollectionCreateResponse> {
    console.log(collectionCreateRequest);
    return this.post(`/collections`, collectionCreateRequest);
  }

  async getCollection(
    collectionReadRequest: CollectionReadRequest
  ): Promise<CollectionReadResponse> {
    console.log(collectionReadRequest);
    return this.get(`/collections/${collectionReadRequest.collection_id}`);
  }

  async deleteCollection(
    collectionDeleteRequest: CollectionDeleteRequest
  ): Promise<CollectionDeleteResponse> {
    console.log(collectionDeleteRequest);
    return this.post(`/collections/delete`, collectionDeleteRequest);
  }

  async updateCollection(
    collectionUpdateRequest: CollectionsUpdateRequest
  ): Promise<CollectionUpdateResponse> {
    console.log(collectionUpdateRequest);
    return this.put(`/collections`, collectionUpdateRequest);
  }

  async getCollectionPosts(
    getCollectionPostsRequest: GetCollectionPostsRequest
  ): Promise<GetCollectionsPostsResponse> {
    console.log(getCollectionPostsRequest);
    return this.get(
      `/collections/${getCollectionPostsRequest.collection_id}/posts`
    );
  }

  async removeCollectionPosts(
    removeCollectionPostsRequest: RemoveCollectionPostsRequest
  ): Promise<RemoveCollectionsPostsResponse> {
    console.log(removeCollectionPostsRequest);
    return this.put(`/collections/posts`, {
      collection_id: removeCollectionPostsRequest.collection_id,
    });
  }

  // login

  async loginAccount(
    accountLoginRequest: AccountLoginRequest
  ): Promise<AccountLoginResponse | undefined> {
    return this.post("/account/login", accountLoginRequest);
  }

  // invites

  async redeemInvite(
    redeemInviteRequest: RedeemInviteRequest
  ): Promise<RedeemInviteResponse | undefined> {
    return this.post("/invite/redeem", redeemInviteRequest);
  }

  // social media accounts

  async getTikTokLoginUrl(): Promise<GetTikTokLoginResponse> {
    return this.get("/tiktok/login/url");
  }

  async createTikTokConnection(
    request: CreateTikTokConnectionRequest
  ): Promise<SuccessResponse | undefined> {
    return this.post("/tiktok/login", request);
  }

  // posts

  async getPostsByInfluencerId(
    getInfluencerPostsRequest: GetInfluencerPostsRequest
  ): Promise<GetInfluencerPostsResponse> {
    console.log("REQUEST", getInfluencerPostsRequest);
    return this.get(`/posts/${getInfluencerPostsRequest.influencer_id}`);
  }
}
